<template>
    <b-modal ref="messageCreateModalDriver" id="messageCreateModalDriver" title="Сообщение водителю" size="md" no-enforce-focus hide-footer :no-close-on-backdrop="true">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <label for="">Номер телефона:</label>
              <b-form-input v-model="perfomerPhone" type="number" @input="updatePerfomerPhone" />
            </b-col>
            <b-col cols="12">
              <label for="">Сообщение:</label>
              <b-form-textarea placeholder="Введите текст..." v-model="mail.message" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <b-button-group class="mt-2">
                <b-button :disabled="submitButtonDisabled" variant="primary" @click="sendmessage()">Отправить</b-button>
                <b-button class=" ml-2" variant="secondary" @click="cancel()">Отменить</b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </template>
  
  <script>
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      ToastificationContent
    },
    props: ['perfomerPhone'],
    data() {
      return {
        mail: {
          message: '',
          phone: this.perfomerPhone
        },
        submitButtonDisabled: false
      }
    },
    mounted() {
      console.log(this.$route.name + 'CreateModal');
    },
    methods: {
      updatePerfomerPhone(event) {
        this.perfomerPhone = event.target.value;
        this.mail.phone = event.target.value;
      },
      cancel() {
        this.$refs.messageCreateModalDriver.hide();
      },
      sendmessage() {
        this.$http
          .post('sms-send', this.mail)
          .then(res => {
            this.$refs.messageCreateModalDriver.hide();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Успех!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Сообщение отправлено!`,
              },
            })
            this.$emit('refresh')
            this.mail = {
              message: ''
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Некорректные данные!',
                icon: 'XIcon',
                variant: 'danger',
                text: err.response.data.errors,
              },
            })
          })
      },
    },
    watch: {
      perfomerPhone(value) {
        if (value) {
          this.mail.phone = value;
        }
      }
    }
  }
  </script>