<template>
    <b-modal
    id="order_history"
    title="История заказа"
    @show="getData"
    @shown="resizeable"
    centered
    no-enforce-focus
    ok-only
    size="lg"
    no-close-on-backdrop
    >
                <b-card class="m-0" style="overflow-x: scroll;">
                    <b-table
                            striped
                            small
                            id="myTable"
                            bordered
                            selectable
                            selected-variant="primary"
                            select-mode="single"
                            responsive="sm"
                            :items="orderHistory"
                            :fields="fields" 
                            head-row-variant="secondary"
                        >
                            <template #cell(checkbox)="row">
                                <input type="checkbox" :value="row.item.id">
                            </template>
                            <template #cell(create_user)="data">
                                {{data.item.create_user ? data.item.create_user.login : ''}}
                            </template>
                            <template #cell(to_addresses)="data">
                                <span v-for="(to_address, idx) in data.item.to_addresses" :key="idx">
                                    <span v-if="to_address">
                                        <span v-if="to_address.type === 'fast_address' || to_address.type === 'address_point'">
                                            {{ to_address.name + ';' }}
                                        </span>
                                        <span v-if="to_address.type === 'address'">
                                            <span v-if="to_address.street_type == 'микрорайон'">
                                                <span>
                                                {{ to_address.street + ' ' + to_address.street_type + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                <span>
                                                {{ to_address.street_type + ' ' + to_address.street + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </template>
                            <template #cell(from_address)="data">
                                <div v-if="data.item.from_address">
                                    <div v-if="data.item.from_address.type === 'fast_address' || data.item.from_address.type === 'address_point'">
                                        {{ data.item.from_address.name }}
                                    </div>
                                    <div v-if="data.item.from_address.type === 'address'">
                                        <div v-if="data.item.from_address.street_type == 'микрорайон'">
                                            <span>
                                                {{ data.item.from_address.street + ' ' + data.item.from_address.street_type + ', ' + data.item.from_address.name }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span>
                                                {{ data.item.from_address.street_type + ' ' + data.item.from_address.street + ', ' + data.item.from_address.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #cell(performer_name)="data">
                                {{data.item.performer==null ? '' :data.item.performer.last_name+' '+data.item.performer.first_name}}
                            </template>
                            <template #cell(performer_phone)="data">
                                {{ data.item.performer ? data.item.performer.phone : '' }}
                            </template>
                            <template #cell(performer_login)="data">
                                <span @dblclick="getLogin(data)" v-if="data.item.performer" class="reference-field">
                                    {{ data.item.id ? data.item.performer.login : '' }}
                                </span>
                              </template>
                            <template #cell(performer_transport)="data">
                                {{data.item.performer==null || data.item.performer.transport==null? '' : data.item.performer.transport.model + ` ${data.item.performer.transport.color}` + ` ${data.item.performer.transport.car_number}`}}
                            </template>
                            <template #cell(distance)="data">
                                {{data.item.distance!=null ? data.item.distance.toFixed(1)+'км' : ''}}
                            </template>
                            <template #cell(allowances)="data">
                                <span v-for="(allowance, index) in data.item.allowances" :key="allowance.id">
                                    {{ data.item.allowances.length - 1 === index ? allowance.name : allowance.name + '; ' }}
                                </span>
                            </template>
                            <template #cell(client_status) = "data">
                                {{ data.item.client_status == 0 ? 'Не оповещён' : 'Оповещён'}}
                            </template>
                            <template  #cell(report)="data">
                                <span class="reference-field">
                                    (***)
                                </span>
                            </template>   
                            <template #cell(history)="data">
                                <span class="reference-field" ref="textDiv">
                                    (***)
                                </span>
                            </template>
                            <template #cell(history_call)="data">
                                <span class="reference-field">
                                    (***)
                                </span>
                            </template>  
                        </b-table>
            </b-card>
    </b-modal>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.css'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props: ["orderId"],
    components: {
        VueContext,
        ToastificationContent
    },
    data() {
        return {
            orderHistory: [],
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'} },
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'} },
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_login', label: 'Логин водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'from_address', label: 'Откуда', sortable: true, thStyle: {width: '220px'} },
                { key: 'to_addresses', label: 'Куда', sortable: true, thStyle: {width: '220px'} },
                { key: 'price', label: 'Цена', sortable: true, thStyle: {width: '150px'} },
                { key: 'bonus_price', label: 'Безнал', sortable: true, thStyle: {width: '150px'} },
                { key: 'phone', label: 'Номер телефона', sortable: true, thStyle: {width: '150px'} },
                { key: 'dop_phone', label: 'Доп. номер', sortable: true, thStyle: {width: '150px'} },
                { key: 'report', label: 'Запросы', sortable: true, thStyle: {width: '150px'} },
                { key: 'past_minute', label: 'ВП', sortable: true, thStyle: {width: '40px'} },
                { key: 'tariff', label: 'Тариф', sortable: true, thStyle: {width: '150px'} },
                { key: 'status', label: 'Статус', sortable: true, thStyle: {width: '150px'} },
                { key: 'created_at', label: 'Время поступления', sortable: true, thStyle: {width: '150px'} },
                { key: 'date_time', label: 'На время', sortable: true, thStyle: {width: '150px'} },
                { key: 'assignmentBy', label: 'Назначил', sortable: true, thStyle: {width: '150px'} },
                { key: 'stat_for_drivers', label: 'Доп. инфо для водителя', sortable: true, thStyle: {width: '200px'} },
                { key: 'info_for_operator', label: 'Доп. инфо для оператора', sortable: true, thStyle: {width: '200px'} },
                { key: 'performer_name', label: 'Водитель', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_transport', label: 'Автомобиль', sortable: true, thStyle: {width: '150px'} },
                { key: 'model_editor_login', label: 'Создал', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_phone', label: 'Номер водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'client_status', label: 'Статус клиента', sortable: true, thStyle: {width: '150px'} },
                { key: 'type', label: 'Тип', sortable: true, thStyle: {width: '150px'} },
                { key: 'allowances', label: 'Надбавки', sortable: true, thStyle: {width: '150px'} },
                { key: 'distance', label: 'Расстояние', sortable: true, thStyle: {width: '150px'} },
                { key: 'history', label: 'История изменения', sortable: true, thStyle: {width: '150px'} },
                { key: 'history_call', label: 'История звонков', sortable: true, thStyle: {width: '150px'} },
                { key: 'commission_price', label: 'Коммиссия', sortable: true, thStyle: {width: '150px'} },
            ],
        }
    },
    methods: {
        resizeable(){
            var thElm;
            var startOffset;
            Array.prototype.forEach.call(
            document.querySelectorAll("table th"),
            function (th) {
                th.style.position = 'relative';
                var grip = document.createElement('div');
                grip.innerHTML = "&nbsp;";
                grip.style.top = 0;
                grip.style.right = 0;
                grip.style.bottom = 0;
                grip.style.width = '5px';
                grip.style.position = 'absolute';
                grip.style.cursor = 'col-resize';
                grip.addEventListener('mousedown', function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                });
                th.appendChild(grip);
            });
            document.addEventListener('mousemove', function (e) {
                if (thElm) {
                    thElm.style.width = startOffset + e.pageX + 'px';
                }
            });
            document.addEventListener('mouseup', function () {
                thElm = undefined;
            });
        },
        getData(){
            this.$http
            .get(`orders/${this.orderId}/history`)
            .then(res => {
                this.orderHistory = res.data
            })
            .catch(err => {
            })
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto;
height: auto;
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }  
.my-danger{
    background-color: #f9cfcf;
}  
.my-success{
    background-color: #8fe2b4;
} .my-secondary{
    background-color: #dcdddf;
} .my-warning{
    background-color: #ffcd9d;
}   
</style>