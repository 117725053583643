<template>
 <div>
    <b-modal
        ref="memosCreateModal"
        hide-footer no-enforce-focus
        id="memosCreateModal"
        title="Служебная записка"
        size="md"
        @show="opened"
        @shown="detectStartTime"
        @hidden="detectEndTime"
        >
            <div>
                <label for="">Подразделение</label>
                <v-select
                    :options="divisions"
                    v-model="division"
                    :reduce="option => option.id"
                    label="name"
                    class="select-size-sm"
                >
                </v-select>
            </div>
            <div class="mt-1">
                <label for="">Тема служебной записки <i style="color: red">*</i></label>
                <v-select
                    :options="memo_subjects"
                    v-model="complaint.memo_subject_id"
                    :reduce="option => option.id"
                    label="name"
                    @input="getMemoTypes"
                    class="select-size-sm"
                />
            </div>
            <div class="mt-1">
                <label for="">Тип служебной записки <i style="color: red">*</i></label>
                <v-select
                    :options="memo_types"
                    v-model="complaint.memo_type_id"
                    :reduce="option => option.id"
                    label="name"
                    :disabled="condition"
                    class="select-size-sm"
                />
            </div>
            <div class="mt-1">
                <label for="">Комментарий</label>
                <b-textarea v-model="complaint.comment"></b-textarea>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="col-md-6">
                    <label for="">ID заказа</label>
                    <b-input  v-model="complaint.order_id" class="form-control" size="sm" type="text"/>
                </div>
                <div class="col-md-6">
                    <label for="">Номер телефона <i style="color: red">*</i></label>
                    <b-input  v-model="complaint.phone" class="form-control" size="sm" type="text" v-mask="'############'" placeholder="Телефон"/>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-1">
                <b-button
                :disabled="submitButtonDisabled"
                class="col-md-3 mt-1 mr-1 align-self-end"
                variant="primary"
                size="sm"
                block
                @click="sendComplaint"
            >
            Ок
            </b-button>
            <b-button
                class="col-md-3 mt-1 align-self-end"
                variant="secondary"
                size="sm"
                block
                @click="cancel"
            >
            Отмена
            </b-button>
            </div>
        </b-modal>
 </div>
</template>

<script>
import { mask } from "vue-the-mask"
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    directives: { mask },
    components:{
        ToastificationContent,
        vSelect
    },
    props:['editOrder', 'userPhone'],
    data(){
        return{
            condition: true,
            complaint:{
                memo_subject_id: '',
                phone: '',
                memo_type_id: '',
                comment: '',
                order_id : ''
            },
            division: null,
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            memo_subjects: [], 
            memo_types: [],
            divisions: [],
            submitButtonDisabled: false,
        }
    },
    mounted(){
       this.$http
        .get('memos/lists')
        .then(res => { 
            this.memo_subjects = res.data.memo_subject
        })
        this.$http
            .get('orders/data')
            .then(res => { 
                this.divisions = res.data.divisions
            })
        let userData = JSON.parse(localStorage.getItem("userData"))
        if (userData.division_id) {
            this.division = userData.division_id
        }
    },
    watch: {
        userPhone(){
            if (this.userPhone) {
                this.complaint.phone = this.userPhone
            }
        }
    },
    methods:{
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
            })
            .catch((err) => {
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'memosCreateForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        opened(){
            if (this.editOrder) {
                this.complaint.division=this.editOrder.division_id
                this.complaint.phone=this.editOrder.phone
                this.complaint.order_id=this.editOrder.id
            }
        },
        getMemoTypes(){
            if (this.complaint.memo_subject_id) {
                this.condition = true
                this.$http
                .get(`memos/types?memo_subject_id=${this.complaint.memo_subject_id}`)
                .then(res => {
                    this.memo_types = res.data
                    this.condition = false
                })
            }else {
                this.condition = true
                this.complaint.memo_type_id = null
            }
        },
        sendComplaint(){
            this.complaint.order_id = this.complaint.order_id ? this.complaint.order_id : undefined
            this.complaint.comment = this.complaint.comment ? this.complaint.comment : undefined
            this.complaint.phone=this.complaint.phone.toString()
            if (this.editOrder) {
                this.complaint.order_id=this.editOrder.id
            }
            this.submitButtonDisabled = true
            this.$http
                .post('memos', this.complaint)
                    .then(res => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Обращение зарегистрировано!`,
                        },
                    })
                    this.complaint={
                        memo_subject_id: '',
                        phone: '',
                        memo_type_id: '',
                        division : '',
                        comment: '',
                        order_id : 1
                    }
                    this.$bvModal.hide('memosCreateModal')
                    }).catch(err=> {
                        let error=err.response.data.errors
                        for(let e in error){
                            var toastText=error[e][0]
                        }
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: toastText,
                        },
                    })
                    })
                    .finally(()=>{
                        this.submitButtonDisabled = false
                    })

        },
        cancel(){
            this.$bvModal.hide('memosCreateModal')
            this.complaint={
                memo_subject_id: '',
                memo_type_id: '',
                phone: '',
                comment: '',
                order_id : null
            }
            this.division = ''
        }
    }

}
</script>

<style></style>