<template>
  <div ref="dialog" class="draggable-modal"
  :style="{
    top: initialDialogY + 'px',
    left: initialDialogX + 'px',
    visibility: isOpened ? 'visible' : 'hidden'
  }">
    <div
    @mousedown="dragStart($event)"
    @mouseup="dragEnd"
    @mousemove="drag($event)"
    class="draggable-modal-header border-bottom" :class="{ 'is-dragging': isDragging }">
      <slot name="header"><div></div></slot>
      <svg @click="$emit('close')" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path fill="#6e6e6e" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"></path>
        </g>
      </svg>
    </div>
    <div class="draggable-modal-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DraggableModal',
  props: {
    isOpened: {
      type: Boolean,
      default: false,
      required: false
    },
    title: String,
  },
  data() {
    return {
      initialDialogX: 0,
      initialDialogY: 0,
      initialMouseX: 0,
      initialMouseY: 0,
      isDragging: false,
    };
  },
  mounted() {
    this.initialDialogX = document.body.offsetWidth / 2 - this.$refs.dialog.offsetWidth / 2
    this.initialDialogY = document.body.offsetHeight / 2 - this.$refs.dialog.offsetHeight / 2
  },
  methods: {
    dragStart(e) {
      this.isDragging = true
      this.initialMouseX = e.clientX
      this.initialMouseY = e.clientY
    },
    dragEnd() {
      this.isDragging = false
    },
    drag(e){
      if (this.isDragging) {
        this.initialDialogY = this.initialDialogY + (e.clientY - this.initialMouseY)
        this.initialDialogX = this.initialDialogX + (e.clientX - this.initialMouseX)
        this.initialMouseX = e.clientX        
        this.initialMouseY = e.clientY        
      }
    },
  }
};
</script>

<style scoped>
  .draggable-modal {
    max-width: 700px;
    width: 100%;
    border: 1px #ccc solid;
    background-color: #fff;
    position: fixed;
    z-index: 999;
  }
  .draggable-modal-header {
    width: 100%;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 5px;
  }
  .draggable-modal-header svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .draggable-modal-header.is-dragging {
    cursor: move;
  }

  .draggable-modal-body {
    padding: 15px;
  }
</style>