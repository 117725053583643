<template>
<form>
  <div class="multiselect">
    <div class="selectBox" @click.stop="showCheckboxes">
      <select>
        <option class="text-center">Надбавки</option>
      </select>
      <div class="select_overlay"></div>
      <div class="overSelect">
        <div id="checkboxes">
          <label :for="'allowance-' + item.id" v-for="item in options" :key="item.id">
            <input @change="selectAndUnselect($event, item.allowance_id)" :checked="selectedAllowance(item.allowance_id)" type="checkbox" :id="'allowance-' + item.id"/>{{item.name}}<em style="fontSize: 14px">({{item.price}}c)</em>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
</template>

<script>
export default {
  props:['options', 'orderAllowances'],
  data(){
    return{
      expanded: false,
      allowances: [],
    }
  },
  mounted() {

  },
  methods:{
    selectedAllowance(id){
      let result = false
      this.orderAllowances.forEach(i => {
        if(i.allowance_id==id){
          result = true
          return
        }
      })
      return result
    },
    selectAndUnselect(e, id){
      this.allowances = JSON.parse(JSON.stringify(this.orderAllowances))
      if(e.currentTarget.checked){
        for(let i of this.options){
          if(i.allowance_id===id){
            if(this.allowances.length!=0){
              for(let j of this.allowances){
                if(i.allowance_id!==j.allowance_id){
                  this.allowances.push({allowance_id: i.allowance_id, name: i.name})
                  break
                }
              }
            }else{
              this.allowances.push({allowance_id: i.allowance_id, name: i.name})
            }
          }
        }
      }else if(!e.currentTarget.checked){
        for(let i of this.options){
          if(i.allowance_id===id){
            if(this.allowances.length!=0){
              for(let j of this.allowances){
                if(i.allowance_id===j.allowance_id){
                  var index = this.allowances.findIndex((el)=>{
                    if (el.allowance_id == i.allowance_id) {
                      return true
                    } else {
                      return false
                    }
                  })
                  this.allowances.splice(index, 1);
                  break
                }
              }
            }
          }
        }
      }
      this.$emit('GetAllowances', this.allowances)
    },
    hideCheckbox(event) {
      const target = event.target;
      if (target.id.indexOf("allowance") != -1) {
        return;
      } else {
        checkboxes.style.display = "none";
        this.expanded = false;
        document.removeEventListener("click", this.hideCheckbox);
      }
    },
    showCheckboxes() {
      var checkboxes = document.getElementById("checkboxes");
      if (!this.expanded) {
        checkboxes.style.display = "block";
        this.expanded = true;
        document.addEventListener("click", this.hideCheckbox);
      } else {
        checkboxes.style.display = "none";
        this.expanded = false;
      }
    },
  },
}
</script>

<style scoped>
.multiselect {
  min-width: 100px;
  max-height: 32px;
}

.selectBox {
  position: relative;
    z-index: 1040;
}

.selectBox select {
  width: 100%;
  padding: 6px 12px;
  color: #005EFF;
  border: 1px solid #005EFF;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  bottom: 0;
  width: 320px;
}
.select_overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 0 0 1px #dadada;
  padding: 0 7px;
  z-index: 9999;
  background-color: #fff;
  overflow: hidden;
  user-select: none;
}

#checkboxes label {
  display: flex;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  margin: 0;
  padding: 3px;
}
#checkboxes label input{
    margin-right: 10px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
  color: #fff;
  cursor: pointer;
}
</style>