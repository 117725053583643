<template>
    <div>
        <b-modal
        @show="getData"
        @shown="detectStartTime, $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', true)"
        @hidden="detectEndTime, $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', false)"
        content-class="order-content"
        :id="$route.name + 'CreateModal'"
        no-close-on-backdrop
        no-enforce-focus      
        no-close-on-esc
        ref="create_order"
        :title="titles" 
        class="order"
        size="lg"
        no-fade
        @close="cancel"
        >
        <div @keyup.esc="cancel">
            <b-row>
                <b-col cols="3">
                    <label for="">Подразделение</label>
                    <v-select
                    v-model="newOrder.division_id"
                    :reduce="option => option.id"
                    placeholder="Подразделение"
                    label="name"
                    class="select-size-sm"
                    @input="getTariffs()"
                    :options="orderData.divisions"
                    >
                    </v-select>
                </b-col>
                <b-col cols="3" class="mt-1 p-0">
                    <div class="custom-control custom-checkbox ">
                        <input @click="getTariffs()" v-hotkey="{ 'alt+Y': altY }" @change="notIssued" v-model="selected" value="A" type="radio" class="custom-control-input" name="vv" id="customCheck1" checked />
                        <label class="custom-control-label" for="customCheck1">Автоназначение<small class="text-primary">(Alt+Y)</small></label>
                    </div>
                    <div class="custom-control custom-checkbox ">
                        <input @click="getTariffs()" v-hotkey="{ 'alt+U': altU }" @change="notIssued" v-model="selected" value="B" type="radio" name="vv" class="custom-control-input" id="customCheck2" />
                        <label class="custom-control-label" for="customCheck2">Не оформлен<small class="text-primary">(Alt+U)</small></label>
                    </div>
                </b-col>
                <b-col cols="3" class="mt-1">
                    <b-button @click="submitInfo" variant="outline-primary" class="btn-icon ml-2" v-b-modal.driver-profilesCreateModal
                        v-b-tooltip.hover.bottom="'Добавить анкету'" v-hotkey="{ 'alt+V': openAnketa }">
                        <feather-icon icon="FilePlusIcon" />
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        class="btn-icon ml-1"
                        v-b-tooltip.hover.bottom="'Служебная записка'"
                        @click="openMemosForm"
                        v-hotkey="{ 'alt+C': openMemosForm}"
                    >
                        <feather-icon icon="FileTextIcon" />
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="mb-1/2 mt-1">
                <b-col cols="7" class="d-flex align-items-center">
                    <div class="input-group">
                        <b-form-input size="sm" id="phoneNumber" autofocus @keyup.enter="focusNumber(), getTariffs()"  :class="errorPhone ? 'border-danger' : ''" v-mask="'############'" v-model="newOrder.phone"
                            placeholder="Номер телефона"  @input="removeBorder(), getTariffs()" />
                        <b-form-input size="sm" id="dopNumber" @keyup.enter="focusAddress(), getTariffs()"   v-mask="'############'" type="text" :class="errorPhone ? 'border-left-danger' : ''" v-model="newOrder.dop_phone"
                            placeholder="Доп.номер" />
                        <b-form-input size="sm" placeholder="Никнейм" disabled />
                    </div>
                </b-col>
                <b-col cols="5" class="d-flex justify-content-end">
                    <div>
                        <label class="d-flex align-items-center">
                            <input type="checkbox" name="" class="cursor-pointer mr-1/2" v-hotkey="{ 'F4': addTime }"
                                @input="Intime" v-model="newOrder.for_time" id="checkTime">
                            На время <small class="text-primary">(F4)</small><br> 
                                <date-picker
                                class="form-control-sm"
                                :disabled="!newOrder.for_time"
                                v-model="newOrder.date_time"
                                v-mask="'##:## ##-##-####'"
                                type="datetime"
                                :disabled-date="disabledDates"
                                :disabled-time="disabledTimes"
                                format="HH:mm DD-MM-YYYY"
                                :value-type="'YYYY-MM-DD HH:mm'"
                                placeholder="HH:mm DD-MM-YYYY"
                                @input="logDate"
                                @input-error="spellDay"
                                >
                                </date-picker>
                        </label>
                        <div v-if="dateValidate && newOrder.for_time" style="font-size: 11px; color: red">
                            Выбранная дата некорректна!
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="7">
                    <div class="address">
                        <h4>Адрес подачи</h4>
                        <b-row>
                            <b-col cols="12" class="mb-1/2">
                                <search-address-v-select
                                @input="newOrder.search_address = $event, getTariffs()"
                                :value="newOrder.search_address"
                                :defaultAddresses="defaultAddresses"></search-address-v-select>
                            </b-col>
                            <b-col cols="12" class="mb-1/2">
                                <label for=""> Инфо о встрече</label>
                                <b-input size="sm" id="info" @input="clearErrorMessage" @keyup.enter="focusSecondAddress(), getTariffs()" autocomplete="off" v-model="newOrder.meeting_info" placeholder="" />
                                <span class="text-danger" style="font-size: 11px" v-if="errorMessageMeetinginfo">{{ errorMessageMeetinginfo }}</span>
                            </b-col>
                            <b-col class="mb-1/2">
                                <h4>Адрес маршрута<small class="text-primary">(Alt+insert)</small></h4>
                            </b-col>
                            <b-col cols="12">
                                <draggable v-model="newOrder.to_addresses">
                                    <b-col
                                    v-for="(to_address, id) in newOrder.to_addresses"
                                    :key="id"
                                    class="mb-1/2 d-flex"
                                    cols="12">
                                        <b-button size="sm" variant="outline-primary" class="btn-icon mr-1/2"
                                            v-if="id === 0" @click="routePush" v-hotkey="{ 'alt+insert': routePush }">
                                            <feather-icon icon="PlusIcon" />
                                        </b-button>
                                        <b-button size="sm" variant="outline-primary" class="btn-icon mr-1/2" v-else
                                            @click="routeMinus(id)">
                                            <feather-icon icon="MinusIcon" />
                                        </b-button>
                                        <search-address-v-select
                                        class="w-100"
                                        @input="to_address.search_address = $event, getTariffs()"
                                        :value="to_address.search_address"
                                        :defaultAddresses="defaultAddresses"
                                        ></search-address-v-select>
                                    </b-col>
                                </draggable>
                            </b-col>
                            <b-col cols="6">
                                <label for="">Доп.инфо для водителя:</label>
                                <b-form-textarea v-model="newOrder.info_for_drivers" placeholder="" />
                            </b-col>
                            <b-col cols="6">
                                <label for="">Доп.инфо для оператора:</label>
                                <b-form-textarea v-model="newOrder.info_for_operator" placeholder="" >  </b-form-textarea>                              </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col cols="5">
                    <div class="info-container">
                        <h4>Тип заказа</h4>
                        <b-row>
                            <b-col class="d-flex justify-content-between mb-1/2" cols="12">
                                <b-form-radio @change="getTariffs()" name="some-radios" v-for="order_type in orderData.order_types" :key="order_type.id"
                                    :value="order_type.id" v-model="newOrder.order_type_id">
                                    {{ order_type.name }}
                                </b-form-radio>
                            </b-col>
                            <b-col cols="12" class="mb-1/2">
                                <label for="">Тариф</label>
                                <v-select 
                                v-model="newOrder.tariff_id"
                                :reduce="option => option.id"
                                placeholder="Тариф"
                                label="name"
                                class="select-size-sm"
                                v-hotkey="{'F8': focusTarif}" 
                                @input="changeTariff"
                                :options="tariffsList" />
                            </b-col>
                            <b-col cols="12">
                                <customSelectAllowance @GetAllowances="GetAllowances" :options="allowances"/>
                            </b-col>
                            <b-col cols="12">
                                <b-row>
                                <b-col >
                                    <label for="priceInCity">Город:</label>
                                    <b-form-input size="sm" id="priceInCity" v-model="newOrderData.distance_in_city" disabled ></b-form-input>
                                </b-col>
                                <b-col class="ml" >
                                    <label  for="priceInterCity">Межгород: </label>
                                    <b-form-input size="sm" id="priceInterCity" v-model="newOrderData.distance_inter_city" disabled> </b-form-input>
                                </b-col>
                                <b-col class="ml">
                                    <label for="distanceInCity">Холостой</label>
                                    <b-form-input size="sm" id="distanceInCity" v-model="newOrderData.free_km" disabled>
                                    
                                    </b-form-input>
                                </b-col>
                                </b-row>
                                <b-row class="mt-2 mb-1">
                                    <b-col>
                                        <input type="checkbox" v-model="checked"> Безнал
                                    </b-col>
                                    <b-col>
                                        <input type="text" :disabled="checked" v-model="newOrder.bonus">
                                    </b-col>
                                </b-row>
                                <div class="bonus" v-if="newOrder.client_login">
                                    <p>{{ newOrder.client_login }}</p>
                                    <p>{{ newOrder.phone }}</p>
                                    <p>{{ newOrder.client_bonus_balance }}</p>
                                </div>
                                <h4 v-if="newOrder.allowances.length > 0">Надбавки: <span class="text-success" style="font-size: 16px" v-for="allowance in newOrder.allowances" :key="allowance.name">{{ allowance.name + ', ' }}</span></h4>
                                <h4>Цена: <b class="text-danger">{{ newOrder.price }}</b> смн.</h4>
                                <b-row>
                                    <b-col>
                                        <h4>Итого: <b class="text-danger">{{ newOrder.price }}</b> смн.</h4>
                                    </b-col>
                                    <b-col>
                                        <h4 style="text-align: right;"><b class="text-danger">{{ newOrder.amount_cash }}</b> смн.</h4>
                                    </b-col>
                                </b-row>
                                <h4>Растояние: <b class="text-primary">{{ newOrder.distance.toFixed(1) }}</b> км.</h4>        
                                <b-button variant="outline-primary" @click="ableDisableInfoModal">Подробности</b-button>

                                <div class="info-modal__interior" v-if="info_modal == true">
                                 <b-row class="mb-1">
                                       <b-col class="info-modal__tariff" cols="2">Тариф</b-col>
                                       <b-col>
                                         <select  @change="changeTariff" v-model="newOrder.tariff_id"
                                               class="form-control form-control-sm">
                                                  <option v-for="tariff in tariffsList" :key="tariff.id" :value="tariff.id">{{ tariff.name }}
                                                   </option>
                                             </select>
                                       </b-col>
                                     </b-row> 
                                     <b-row class="info-modal__title">
                                       Городской
                                     </b-row>
                                     <div class="info-modal__text">             
                                        Поездки внутри города от<span > {{tariff_info.min_price}} смн.</span>
                                      <div>В стоимость поездки входят <span>3</span> км дороги, после <span>{{tariff_info.price_km_city}} смн/км</span>.</div>
                                     </div>
                                <b-row class="info-modal__title">
                                     Межгород
                                </b-row>
                                <div class="info-modal__text">
                                    Поездки вне города от <span > {{tariff_info.min_price}} смн.</span>
                                    <div>В стоимость поездки входят <span>3</span> км дороги, после <span> {{tariff_info.price_km_intercity}} смн/км</span>.</div>
                                </div>
                               
                                        <b-row class="info-modal__title">
                                            Надбавки
                                        </b-row>
                                        <div class="info-modal__allowances">
                                            <ul v-for="allowance in tariff_info.allowances" :key="allowance.name">
                                            <li>{{allowance.name}} - {{allowance.price}} смн.</li>
                                            
                                            </ul>
                                        </div>
                                     <div class="info-modal__text">
                                        <b>Бесплатное ожидание</b> - <span>{{tariff_info.free_waiting_of_client_in_minute}} мин.</span> , после <span>{{tariff_info.price_hour}}</span> дирам/мин..
                                   </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
            <template #modal-footer>
                <button :disabled="IsDisabled || isSubmitButtonDisabled" @click="addOrder" v-hotkey="{ 'ctrl+Enter': addOrder }"
                    class="btn btn-primary btn-md">Добавить</button>
                <button @click="cancel" class="btn btn-secondary btn-md">Отмена</button>
            </template>
        </b-modal>
        <modal-form></modal-form>
        <modal-alert :notificationTitle="notificationTitle" :notificationDescription="notificationDescription"  @cancelOrder=cancelOrder ></modal-alert>
    </div>
</template>
<script>
import ModalAlert from '@/views/component/Modal/ModalAlert/ModalAlert.vue'
import customSelectAllowance from '../../customSelect/customSelectAllowance.vue'
import vSelect from 'vue-select'
import searchAddressVSelect from '../../searchAddressVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import modalForm from "@/views/component/Modal/ModalDriver/modalForm.vue"
import draggable from 'vuedraggable'
import { mask } from "vue-the-mask"
import SearchAddressVSelect from "../../searchAddressVSelect.vue"
import {eventBus} from "@/main.js"

export default {
    directives: { mask },
    props: ['orderData', 'orderClone'],
    components: {
    modalForm,
    draggable,
    searchAddressVSelect,
    ToastificationContent,
    vSelect,
    SearchAddressVSelect,
    customSelectAllowance,
    ModalAlert
},
    data() {
        return {
            notificationTitle: 'Оповещение',
            notificationDescription: 'Данные безвозвратно будут удалены!',
            tariff_info: [],
            info_modal: false,
            allowances: [],
            defaultAddresses: [],
            divisions: [],
            errorPhone: null,
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            newOrderData: {
                distance_inter_city: null,
                distance_in_city: null, 
                free_km: null,
                price_in_city: null,
                price_inter_city: null,
            },
            checked: false,
            indate: false,
            tariffsList: [],
            orders: [],
            newOrder: {
                division_id: 1,
                phone: undefined,
                dop_phone: undefined,
                auto_assignment: 1,
                not_issued: 0,
                for_time: 0,
                date_time: undefined,
                search_address: undefined,
                to_addresses: [{}],
                number_of_passengers: undefined,
                meeting_info: undefined,
                info_for_operator: "",
                info_for_drivers: '',
                order_type_id: 1,
                tariff_id: 1,
                price: 0,
                amount_cash: 0,
                distance: 0.0,
                allowances: [],
                bonus: 0,
                client_login: null,
                client_bonus_balance: 0
            },
            searchTimeout: null,
            selected: 'A',
            isSubmitButtonDisabled: false,
            tariff_data: [],
            tariff_data_id: 1,
            tariff_data_info: {},
            titles: 'Добавить заказ',
            dateValidate: false,
            currentDay: undefined,
            inputId: 2,
            isTrue: true,
            errorMessageMeetinginfo: null
        }
    },
    mounted() {
        this.$root.create_order_modal = this.$refs.create_order
        this.currentDay = new Date(new Date().getTime() - 86400000)
        this.getTariffs()
        this.newOrder.division_id = JSON.parse(localStorage.getItem('userData')).division_id
    },
    computed: {
        IsDisabled() {
            if (!this.newOrder.search_address && !this.newOrder.not_issued) {
                return true
            } else if (!this.newOrder.search_address && this.newOrder.not_issued) {
                return false
            } else if (this.newOrder.search_address && this.newOrder.not_issued) {
                return false
            }
        },
        phoneNumber() {
            return this.$store.state.caller.phoneNumber
        },
    },
    watch: {
        phoneNumber(newVal) {
            if (newVal) {
                this.newOrder.phone = newVal.length === 9 ? '992' + newVal : newVal
            }
        },
        newOrder: {
            handler(newValue){
                if (newValue.search_address && this.isTrue) {
                    document.getElementById("info").focus()
                    this.isTrue = false
                }
            },
            deep: true
        }
        
    },
    methods: {
        clearErrorMessage() {
            this.errorMessageMeetinginfo = null
        },
        getTariffs() {
            this.$http
            .get('orders/tariff-list', {params: {division_id: this.newOrder.division_id}})
            .then(res => {
                this.tariffsList = res.data
                if (this.tariffsList.length > 0) {
                    this.newOrder.tariff_id = this.tariffsList[0].id
                    this.calcPrice()
                }
            })
            .catch(err => {
                console.log(err);
            })
        },
        submitInfo() {
            setTimeout(()=> {
                eventBus.$emit('sendInfo', {
                    division_id: this.newOrder.division_id,
                    phone: this.newOrder.phone
                })
            }, 200)
        },
        ableDisableInfoModal(){
            this.info_modal = !this.info_modal 
        },
        getTariffInfoModal(){
            this.$http
                .get(`/orders/tariff-info/${this.newOrder.tariff_id}`)
                .then(res => {
                    this.tariff_info = res.data
                })
        },
        logDate(){
            let selectedTIme = new Date(this.newOrder.date_time).getTime()
            let actualTime = new Date().getTime()
            this.dateValidate = selectedTIme < actualTime
        },
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'orderCreateForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        disabledDates(date){
            return date < this.currentDay
        },
        disabledTimes(date){
            return date < new Date (new Date().getTime() + 900000)
        },
        getTariffInfo() {
            this.$http
                .get(`tariffs/${this.tariff_data_id}/edit`)
                .then(res => {
                    this.tariff_data_info = res.data
                })
        },
        removeBorder(){
            if(this.errorPhone) {
                this.errorPhone = null
            }
        },
        focusNumber(){
            document.getElementById("dopNumber").focus()
        },
        focusAddress(){
            document.getElementsByClassName("vs__search")[2].focus()
        },
        focusSecondAddress(){
            if (this.newOrder.meeting_info) { 
                document.getElementsByClassName("vs__search")[3].focus()
            }
        },
        focusInfo(){
            document.getElementById("info").focus()
        },
        getData() {
            this.$http
                .get(`orders/allowances`, {params: {tariff_id: this.newOrder.tariff_id}})
                .then(res => {
                    this.allowances = res.data
                })
            this.$http
                .post('directory/search-addresses')
                .then(res => {
                    this.defaultAddresses = res.data
                })
            this.$http
                .get(`tariffs`)
                .then(res => {
                    this.tariff_data = res.data
                })
            this.getTariffInfoModal()
        },
        GetAllowances(allowances) {
            this.newOrder.allowances = allowances
            this.calcPrice()
        },
        changeTariff() {
            this.$http
                .get(`orders/allowances`, {params: {tariff_id: this.newOrder.tariff_id}})
                .then(res => {
                    this.allowances = res.data
                })
            this.newOrder.allowances = []
            this.calcPrice()
            this.getTariffInfoModal()
        },
        Intime() {
            this.newOrder.for_time = !this.newOrder.for_time
        },
        openMemosForm() {
            this.$bvModal.show('memosCreateModal')
            this.$emit("sendNumber", this.newOrder.phone)
        },
        calcPrice() {
            let calcData = {
                allowances: [],
                search_address_id: this.newOrder.search_address && this.newOrder.search_address.id ? this.newOrder.search_address.id : undefined,
                tariff_id: this.newOrder.tariff_id,
                to_addresses: [],
                client_phone: undefined,
                division_id: this.newOrder.division_id,
                order_type: this.newOrder.order_type_id,
                active_bonus: this.checked ? 1 : 0
            }
            // to_addresses: "[{\"search_address_id\":3}]"
            this.newOrder.to_addresses.forEach((item) => {
                if (item.search_address) {
                    calcData.to_addresses.push({ search_address_id: item.search_address.id })
                }
            })
            // calcData.client_phone = calcData.client_phone ? calcData.client_phone : undefined
            calcData.to_addresses = JSON.stringify(calcData.to_addresses)
            // allowances : "[{\"allowance_id\":3},{\"allowance_id\":2}]"
            calcData.allowances = JSON.stringify(this.newOrder.allowances)
            this.$http.post('calculate', calcData)
            .then((res) => {
                this.newOrder.price=res.data.amount
                this.newOrder.distance=res.data.distance
                this.newOrder.order_type_id=res.data.order_type
                this.newOrder.amount_cash = res.data.amount_cash
                this.orderData.bonus = res.data.bonus_price
                this.newOrder.client_login = res.data.client_login
                this.newOrder.client_bonus_balance = res.data.client_bonus_balance
                this.newOrderData.distance_in_city = res.data.distance_in_city
                this.newOrderData.free_km = res.data.free_km
                this.newOrderData.distance_inter_city = res.data.distance - res.data.distance_in_city
                this.newOrderData.distance_inter_city = this.newOrderData.distance_inter_city.toFixed(2)
            })
            .catch((err) => {
                
            })
        },
        openAnketa() {
            this.$bvModal.show('driver-profilesCreateModal')
        },
        focusTarif(){
            document.getElementsByClassName("vs__search")[2].focus()
        },
        openAllowance() {
            let allowanceForm = document.getElementById('allowanceForm')
            allowanceForm.click()
        },
        altU() {
            let check = document.getElementById('customCheck2')
            check.click()
        },
        altY() {
            let check = document.getElementById('customCheck1')
            check.click()
        },
        addTime() {
            let checkTime = document.getElementById('checkTime')
            checkTime.click()
        },
        notIssued() {
            if (this.selected == 'A') {
                this.newOrder.auto_assignment = 1
                this.newOrder.not_issued = 0
            }
            else if (this.selected == 'B') {
                this.newOrder.auto_assignment = 0
                this.newOrder.not_issued = 1
            }
        },
        addOrder() {
            if (this.isSubmitButtonDisabled) return
            this.newOrder.allowances.forEach(el => {
                delete el.name
            })
            if(!this.newOrder.meeting_info){
                this.errorMessageMeetinginfo = 'Это поле объязательна для заполнения'
            } else {
                if (this.selected == 'A') {
                    this.newOrder.auto_assignment = 1
                    this.newOrder.not_issued = 0
                }
                else if (this.selected == 'B') {
                    this.newOrder.auto_assignment = 0
                    this.newOrder.not_issued = 1
                }
                let orderJSON = JSON.parse(JSON.stringify(this.newOrder))
                orderJSON.to_addresses = []
                for (let address of this.newOrder.to_addresses) {
                    if(address.search_address) {
                        orderJSON.to_addresses.push({search_address_id: address.search_address.id})                   
                    }
                }
                if (!orderJSON.search_address) {
                    orderJSON.search_address = undefined
                }
                orderJSON.to_addresses = JSON.stringify(orderJSON.to_addresses)
                orderJSON.for_time ? orderJSON.for_time : undefined
                orderJSON.allowances = orderJSON.allowances.length > 0 ? JSON.stringify(orderJSON.allowances) : undefined
                orderJSON.search_address_id = this.newOrder.search_address && this.newOrder.search_address.id ? this.newOrder.search_address.id : undefined
                orderJSON.search_address = undefined
                this.isSubmitButtonDisabled = true
                this.$http
                    .post('orders', orderJSON)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: `Заказ успешно добавлен!`,
                            },
                        })
                        this.newOrder = {
                            division_id: 1,
                            phone: undefined,
                            dop_phone: undefined,
                            auto_assignment: 1,
                            not_issued: 0,
                            for_time: 0,
                            date_time: undefined,
                            search_address: undefined,
                            to_addresses: [{}],
                            number_of_passengers: undefined,
                            meeting_info: undefined,
                            info_for_operator: "",
                            info_for_drivers: '',
                            order_type_id: 1,
                            tariff_id: 1,
                            price: 0,
                            amount_cash: 0,
                            distance: 0.0,
                            allowances: [],
                            bonus: 0,
                            client_login: null,
                            client_bonus_balance: 0
                        }
                        this.titles = 'Добавить заказ'
                        this.allowancesName = []
                        this.$emit('refresh')
                        this.$bvModal.hide(this.$route.name + 'CreateModal')
                    }).catch(err => {
                        let error = err.response.data.errors
                        this.errorPhone = error.phone
                        for (let e in error) {
                            var toastText = error[e][0]
                        }
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Некорректные данные!',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: toastText,
                            },
                        })
                    }).finally(()=>{
                        this.isSubmitButtonDisabled = false
                    })
            }
        },
        routePush() {
            this.newOrder.to_addresses.push({})
            if (this.newOrder.to_addresses.length > 1) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
        routeMinus(id) {
            let deleted = this.newOrder.to_addresses.splice(id, 1);
            if (this.newOrder.to_addresses.length > 1) {
                this.disabled = false
            } else {
                this.disabled = true
            }
            if (deleted[0].search_address) {
                this.calcPrice()
            }
        },
        cancel(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$bvModal.show('cancelAlert')
        },
        cancelOrder() {
            this.$refs['create_order'].hide()
            this.$bvModal.hide('cancelAlert')
            this.newOrder = {
                division_id: 1,
                phone: undefined,
                dop_phone: undefined,
                auto_assignment: 1,
                not_issued: 0,
                for_time: 0,
                date_time: undefined,
                search_address: undefined,
                to_addresses: [{}],
                number_of_passengers: undefined,
                meeting_info: undefined,
                info_for_operator: "",
                info_for_drivers: '',
                order_type_id: 1,
                tariff_id: 1,
                price: 0,
                amount_cash: 0,
                distance: 0.0,
                allowances: [],
                bonus: 0,
                client_login: null,
                client_bonus_balance: 0
            }
            this.selected = "A"
            this.allowancesName = []
            this.isTrue = true
        },
        spellDay(value){
            if (value.length >= 5) {
                if (value > this.$moment().format('HH:mm')) {
                    this.newOrder.date_time = this.$moment().format(`YYYY-MM-DD ${value}`)
                } else {
                    this.newOrder.date_time = this.$moment().add(1, 'days').format(`YYYY-MM-DD ${value}`)
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@media (min-width: 992px) {
    .modal-lg {
        max-width: 1000px !important;
    }
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: auto !important;

    }

}

.list-group-item {
    transition: all 1s
}

.vs__dropdown-menu {
    z-index: 9999 !important;
}

.address{ 
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
}

.info-container {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
}

.adress-fields {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 550px;
    height: 250px;
    margin-left: 14px;
}
.test{
    overflow-y: auto;
    overflow-x: hidden;
    height: 55px;
}
/* width */
.test::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.test::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(156, 155, 155);
    border-radius: 10px;
}

/* Handle */
.test::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.924);
    border-radius: 10px;
}

/* Handle on hover */
.test::-webkit-scrollbar-thumb:hover {
    background: rgb(166, 166, 169);
}

.option-fields {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 390px;
    margin-left: 22px;
    padding: 10px;
}

.info-fields {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 550px;
    margin-top: -120px;
    margin-left: 14px;
}

select {
    border-radius: 5px;
}

.custom_select {
    padding: 0;
    padding-right: 10px;
}

.custom_select span.vs__selected {
    display: none;
}

.custom_select div.vs__dropdown-toggle {
    border: 1px solid #005EFF;
    color: #005EFF;
}

.custom_select div.vs__dropdown-toggle::before {
    content: "Надбавки";
    position: relative;
    left: 55px;
    top: 9px;
    font-size: 13px;
    color: #005EFF;
}

.custom_select #vs3__listbox {
    width: 320px;
}

.mx-calendar-content td, .mx-calendar-content th {
    min-width: fit-content;
    width: fit-content;
    text-align: center;
}
.mb-1\/2{
    margin-bottom: 0.5rem;
}
.mr-1\/2{
    margin-right: 0.5rem;
}
.mx-input{
    height: 2.1rem;
}
.info-modal__interior{
    position: fixed;
    z-index: 1000;
    top:23px;
    left: 25px;
    max-width: 350px;
    min-height:565px;
    background: rgb(255, 253, 253);
    padding: 19.6px;
    border-radius: 4px;
}

.info-modal__tariff{
    margin-top: 5px;
    font-weight: bold;
}
.info-modal__text{
    margin: 0 auto;
    margin: 8px 0 8px 0;
}
.info-modal__allowances{
    margin-top: 10px;
    line-height: 10px;
}

.info-modal__title{
    margin: 8px 0 8px 0;
    font-weight: bold;
    margin: 0 auto;
    min-width: 100%;
}
@media (min-width:1900px){
    .info-modal__interior{
         max-width: 500px;
    }
.order-content{
      margin-left: 30%;
  }
}
@media (min-width:1780px){
    .info-modal__interior{
        left: 4%;
    }
.order-content{
      margin-left: 25%;
  }
}
@media (min-width:1580px){
    .info-modal__interior{
         margin-left: 4%;
    }
.order-content{
      margin-left: 15%;
  }
}
@media (max-width:1580px){
    .info-modal__interior{
         margin-left: 0%;
    }
    .order-content{
      margin-left: 200px;
  }
}
@media (max-width:1450px){
  .order-content{
      max-width: 920px;
}
}
@media (max-width:1359px){
  .order-content{
      margin-left: inherit;
}
}
.ml {
    margin-left: -25px;
}

.bonus {
    display: flex;
    justify-content: space-around;
    background: yellow;
    margin: 0;
    padding: 0;
}

.bonus p {
    margin: 0;
    padding: 0;
}
</style>
