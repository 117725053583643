<template>
    <div>
        <b-button variant="outline-primary" :disabled="disabled" @click="isOpened = !isOpened" style="width: 100%;"> Надбавки</b-button>
        <draggable-modal
            :isOpened="isOpened"
            @close="close"
            v-hotkey="{ 'enter': addAllowances }"
          >
            <template #header>
                Надбавки
            </template>
            <div>
                <b-table 
                v-hotkey="{ 'enter': addAllowances }"
                @close="close"
                    striped
                    small
                    id="myTables"
                    bordered
                    responsive="sm"
                    :items="all"
                    :fields="fields"
                    @row-clicked="checkboxClicked"
                    >
                    <template #cell(count)="data">
                        <input class="allowance-input" v-if="data.item.type == 'custom_type' || data.item.type == 'custom_not_add_to_price' || data.item.type == 'custom_type_multiply' || data.item.type == 'minute'"   v-model="data.item.value" type="number">
                        <input style="margin-left: 75px;" v-else v-model="selectedOptions" :value="data.item.allowance_id" type="checkbox">
                    </template>
                </b-table>
            </div>
            <div class="cancel-button">
                <b-button size="sm" @click="addAllowances">
                    Закрыть
                    <span>(Enter)</span>
                </b-button>
            </div>
        </draggable-modal>
    </div>
</template>
  
  <script>
  import DraggableModal from '../Modal/DraggableModal.vue';
  export default {
    components: {
        DraggableModal
    },
    props: ["options", "editOrder", "disabled"],
    data() {
      return {
        selectedOptions: [],
        allowances: [],
        isOpened: false,
        all: [],
        fields: [
            { key: 'name', label: 'Наименование', thStyle: {width: '150px'}},
            { key: 'price', label: 'Цена', thStyle: {width: '80px'}},
            { key: 'count', label: 'Количество', thStyle: {width: '80px'}},
        ],
      }
    },
    watch: {
        editOrder(newValue, oldValue){
            if (this.options) {
                this.all = JSON.parse(JSON.stringify(this.options))
            }
        },
        options(){
            if (this.options) {
                this.all = JSON.parse(JSON.stringify(this.options))
            }
        }
    },
    updated() {
        if (this.editOrder) {
            this.selectedOptions.length = 0
                        this.editOrder.forEach(el => {
                            if (el.type !== 'custom_type' || el.type !== 'custom_not_add_to_price') {
                                this.selectedOptions.push(el.allowance_id)
                            }
                            this.all.forEach(e =>  {
                                    if (e.allowance_id == el.allowance_id && (e.type == 'custom_type' || e.type == 'custom_not_add_to_price')) {
                                        e.value = el.value
                                    }
                                })
                        })
        }
    },
    methods: {
        checkboxClicked(item, index, event) {
            if (event.target.childNodes[0].tagName == 'INPUT') {
                event.target.childNodes[0].click()
            }else {
                if (event.target.parentNode.childNodes[2].childNodes[0].type == 'number') {
                    event.target.parentNode.childNodes[2].childNodes[0].focus()
                }else {
                    event.target.parentNode.childNodes[2].childNodes[0].click()
                }
            }
        },
        close() {
            this.isOpened = false
        },
        addAllowances(){
            this.allowances.length = 0
            this.all.forEach(el => {
                if (el.value && (el.type == 'custom_type' || el.type == 'custom_not_add_to_price' || el.type == 'custom_type_multiply' || el.type == 'minute')) {
                        this.allowances.push({allowance_id: el.allowance_id, value: el.value, name: el.name + '(' + el.value+ ')'})
                }
                this.selectedOptions.forEach(id => {
                   if (el.allowance_id == id && (el.type == 'fix' || el.type == 'percent')){
                        this.allowances.push({allowance_id: el.allowance_id, value: 1, name: el.name })
                    }
                })
            });
            this.$emit("GetAllowances", this.allowances);
            this.isOpened = false
        }
    },
  }
  </script>
  <style scoped>
  .allowance-input {
    border: none;
    outline: none;
    text-align: center;
    position: relative;
    width: 165px;
  }
  .cancel-button {
    display: flex;
    justify-content: center;
  }
  </style>