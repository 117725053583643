<template>
  <b-modal 
  ref="messageCreateModal" 
  no-enforce-focus 
  id="messageCreateModal" 
  title="Сообщение клиенту" 
  size="md" 
  hide-footer 
  :no-close-on-backdrop="true"
  @show="photoNumber"
  >
    <!-- Код модального окна -->
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <label for="">Номер телефона:</label>
            <b-form-input v-model="phone" type="number" />
          </b-col>
          <b-col cols="12">
            <label for="">Сообщение:</label>
            <b-form-textarea placeholder="Введите текст..." v-model="mail.message" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button-group class="mt-2">
              <b-button :disabled="submitButtonDisabled" variant="primary" @click="sendmessage()">Отправить</b-button>
              <b-button class=" ml-2" variant="secondary" @click="cancel()">Отменить</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Конец кода модального окна -->
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent
  },
  props: ['clientPhone'],
  data() {
    return {
      mail: {
        message: undefined,
        phone: this.phone
      },
      submitButtonDisabled: false,
      phone: null,
    }
  },
  methods: {
    cancel() {
      this.$refs.messageCreateModal.hide();
      this.submitButtonDisabled = false
      this.mail = {
        message: undefined,
        phone: null
      }
    },
    photoNumber() {
      this.phone = this.clientPhone
    },
    sendmessage() {
      this.mail.phone = this.phone
      this.submitButtonDisabled = true
      this.$http
        .post('sms-send', this.mail)
        .then(res => {
          this.cancel();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успех!',
              icon: 'CheckIcon',
              variant: 'success',
              text: `Сообщение отправлено!`,
            },
          })
          this.$refs.messageCreateModal.hide();
          this.$emit('refresh')
          this.mail = {
            message: ''
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Некорректные данные!',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.errors,
            },
          })
        })
        .finally(()=>{
          this.submitButtonDisabled = false
        })
      // Закрытие модального окна при отправке сообщения
    },
  },
}
</script>