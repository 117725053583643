<template>
    <div>
        <b-modal 
        no-enforce-focus 
        id="cancelAlert" 
        :title="`${notificationTitle} пользователю!`" 
        size="md"
        centered 
        hide-footer 
        :no-close-on-backdrop="true"
        @close="closeNotification()"
        >
        <div>
            <b-row>
                <b-col cols="12">
                         <b-row>
                            <b-col cols="12" >
                                <h1>{{ notificationTitle }}!</h1>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" >
                                <p style="color: red;">{{ notificationDescription }}</p >
                            </b-col>
                        </b-row>
                        <b-row>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button class="mt-2 col-md-5"variant="primary" @click="openNotification" v-hotkey="{ 'Enter': openNotification }">Да</b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="closeNotification">Отмена</b-button>
                    </b-col>
                </b-row>
        </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    props: {
        notificationTitle: {
            type: String,
            default: ''
        },
        notificationDescription: {
            type: String,
            default: ''
        }
    },
    methods: {
        closeNotification() {
            this.$bvModal.hide('cancelAlert')
        },
        openNotification() {
            this.$emit('cancelOrder')
        }
    }
}
</script>